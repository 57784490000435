import { CAR_MODULE_PATHS, NEWS_MODULE_PATHS, PARENT_URL } from '@apps/config';

export type CommonPathType = {
  title: string;
  path: string;
  children?: string[];
};

export type MegaMenuType = {
  title: string;
  children?: CommonPathType[];
};

export const MODULE_PATHS: CommonPathType[] = [
  {
    title: 'Home',
    path: '/',
    children: ['/', ...NEWS_MODULE_PATHS],
  },
  {
    title: 'AutoFacts',
    path: '/cars',
    children: CAR_MODULE_PATHS?.filter(
      (item) => !item.includes('/cars/ev-overview')
    ),
  },
  {
    title: 'Marketplace',
    path: PARENT_URL,
    children: [],
  },
  {
    title: 'Compare EV',
    path: '/cars/ev-overview',
    children: CAR_MODULE_PATHS?.filter((item) =>
      item.includes('/cars/ev-overview')
    ),
  },
];

export const MODULE_MEGA_PATHS: MegaMenuType[] = [
  {
    title: 'Pages',
    children: MODULE_PATHS,
  },
];
