import { ReactNode, useEffect, useMemo, useState } from 'react';
import AnimatedBurger from '@apps/components/AnimatedBurger';
import StyledImage from '@apps/components/Image';
import SearchInput from '@apps/components/SearchInput';
import {
  CAR_MODULE_PATHS,
  COMMON_PATHS,
  NEWS_MODULE_PATHS,
  WEBVIEW_NO_HEADER,
} from '@apps/config';
import { SocialIcons } from '@apps/config/icons.config';
import useLanguageItems from '@apps/config/language-dropdown.config';
import { useScrollDirection } from '@apps/config/windowscroll.config';
import usePostMsgContext from '@apps/containers/WebviewProvider/hooks';
import { localeConversions } from '@apps/utils/locale-conversion';
import BackIcon from '@stack/assets/icons/back-icon.svg';
import SearchIcon from '@stack/assets/icons/search-primary.svg';
import Logo from '@stack/assets/logo/logo.png';
import SelectDropdown from '@stack/ui/components/Select';
import classNames from 'classnames';
import { includes } from 'lodash';
import { useAmp } from 'next/amp';
import { AppInitialProps } from 'next/app';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { MODULE_PATHS } from './setting';
import {
  CategoriesBoxWrapper,
  CollapseHolder,
  CollapseInner,
  DeskLogoInner,
  HeaderBarSection,
  LanguageIconBox,
  LanguageIconWrapper,
  LanguageTag,
  LanguageWrapper,
  LogoWrapper,
  MbLogoInner,
  MegaMenuItem,
  MenuBurgerWrap,
  MenuItem,
  MenuTitle,
  NavBarItem,
  NavBarSection,
  StyledButton,
  StyledInput,
  StyledMainWrapper,
  StyledSearchBox,
  StyledTopLeftMenuWrapper,
  SubCateBoxWrapper,
  SubMegaMenu,
  SubMenu,
} from './styles';

export type HeaderSettings = {
  renderNavItems?: ReactNode;
};

type NavBarProps = AppInitialProps & {
  webView: boolean;
  config?: HeaderSettings;
  containerNoCss?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const Navbar: React.FC<NavBarProps> = (props) => {
  const { pageProps, webView, config = {}, containerNoCss = false } = props;
  const { renderNavItems: customNavItems = undefined } = config;
  const settings = pageProps?.settings;

  // ======================= HOOKS
  const router = useRouter();
  const { lang, t } = useTranslation('common');
  const languageOptions = useLanguageItems();
  const pathname = usePathname();
  const { clickEvent, clickAction } = usePostMsgContext();
  const isAmp = useAmp();

  // ======================= VARIABLES
  const routerPathName = router.pathname;
  const isNoHeaderPath = WEBVIEW_NO_HEADER.includes(routerPathName);
  const isNewsModule = ['/', ...NEWS_MODULE_PATHS, ...COMMON_PATHS].includes(
    routerPathName
  );
  const isCarModule = CAR_MODULE_PATHS?.filter(
    (item) => !item.includes('/cars/ev-overview')
  ).includes(routerPathName);
  const isEVCompare = CAR_MODULE_PATHS?.filter((item) =>
    item.includes('/cars/ev-overview')
  ).includes(routerPathName);

  const isHomePage = ['/webview', '/webview/cars'].includes(routerPathName);
  const selectedCategories = router?.query?.category as string;
  const defaultLang = languageOptions.find((lo) => lo.value === lang);
  const postCategories = settings?.post_category ?? [];
  const selectedSubCategories = router?.query?.slug?.[0] ?? '';
  const isDetails = (router?.query?.slug?.[1] && webView) ?? false;
  const hideHeader = Boolean(isNoHeaderPath);
  const newLang = localeConversions(lang);
  const hasSubMenu = postCategories.find((pc: any) => {
    return pc.post_category_slug === selectedCategories;
  })?.post_category_item;

  const currentRoute = useMemo(() => {
    const routeSplitter = router?.asPath.split('/');
    const length = routeSplitter.length;
    const lastPath = routeSplitter[length - 1];
    return lastPath;
  }, [router]);

  // ======================= STATE
  const [collapse, setCollapse] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [type, setCollapseType] = useState('');
  const scrollDirection = useScrollDirection();
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const [selectedMainNavItem, setSelectedMainNavItem] = useState('');
  const [selectedOption, setSelectedOption] = useState(
    defaultLang?.label ?? 'EN'
  );

  // ======================= EFFECTS
  useEffect(() => {
    setSelectedNavItem(selectedCategories ?? currentRoute);
  }, [selectedCategories, currentRoute]);

  useEffect(() => {
    setSelectedMainNavItem(routerPathName);
  }, [routerPathName]);

  useEffect(() => {
    const dlang = languageOptions.find((lo) => lo.value === lang);
    setSelectedOption(dlang?.label as string);
  }, []);

  useEffect(() => {
    if (collapse) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'auto';
    return () => {};
  }, [collapse]);

  useEffect(() => {
    setCollapse(false);
    if (!pathname.includes('/search')) {
      setKeyword('');
    }
  }, [pathname]);

  // ======================= EVENTS
  const handleChange = (value: string) => {
    setSelectedOption(value);
    const currentPath = router?.asPath ?? '/';
    // reset page number when change language
    const updatedPath = currentPath.replace(/([&?]page=\d+)/, '');
    let newUrl = updatedPath;
    if (router.route === '/author/[author]') {
      newUrl = '/';
    }
    if (webView && isDetails) {
      clickAction(`${updatedPath}`, false, {
        lang: value,
      });
      return;
    }
    router.replace(newUrl, newUrl, { locale: value });
    setCollapse(false);
  };

  const onHandleCollapse = (state: boolean, inType?: string) => {
    if (inType) {
      setCollapseType(inType);
    }
    if (type !== inType) {
      setCollapse(true);
      return;
    }
    setCollapse(state);
  };

  const handleInputChange = (event: any) => {
    const { target } = event;
    const { value } = target;
    setKeyword(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (keyword) {
      if (webView) {
        router.push(`/webview/search/${keyword}`);
        return;
      }
      router.push(`/search/${keyword}`);
    }
  };

  // ======================= HELPERS
  const getRedirectUrl = (baseUrl: string) => {
    return webView ? '/webview' + baseUrl : baseUrl;
  };

  // ======================= RENDER FUNCTIONS
  const renderNavItems = () => {
    const isHomeSelected = selectedNavItem === 'latest';
    if (customNavItems) return <>{customNavItems}</>;
    if (isDetails || !isNewsModule || isNoHeaderPath) return null;
    return (
      <div
        style={{
          borderBottom: webView ? '1px solid #ECECEC' : '1px solid #ECECEC',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <CategoriesBoxWrapper $show={scrollDirection !== 'down'}>
          <NavBarSection
            style={{
              padding: '5px 20px',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'scroll',
              whiteSpace: 'nowrap',
              margin: '0 auto',
              maxWidth: 1240,
              scrollbarWidth: 'none',
            }}
          >
            <NavBarItem
              className={classNames('nav-item text-sm-unchanged ', {
                'nav-ani-selected': isHomeSelected,
                'nav-ani': !isHomeSelected,
              })}
            >
              <Link
                href={getRedirectUrl(`/latest`)}
                onClick={() => setSelectedNavItem('latest')}
              >
                {t('latest')}
              </Link>
            </NavBarItem>
            {postCategories &&
              postCategories.map((navItem: any) => {
                const navItemSlug = navItem.post_category_slug;
                const isSelected = navItemSlug === selectedNavItem;
                return (
                  <NavBarItem
                    key={navItem.post_category_slug}
                    className={classNames('nav-item text-sm-unchanged', {
                      'nav-ani-selected': isSelected,
                      'nav-ani': !isSelected,
                    })}
                  >
                    <Link
                      href={getRedirectUrl(`/${navItem.post_category_slug}`)}
                      onClick={() =>
                        setSelectedNavItem(navItem.post_category_slug)
                      }
                    >
                      {navItem?.post_category_name[newLang]}
                    </Link>
                  </NavBarItem>
                );
              })}
          </NavBarSection>
        </CategoriesBoxWrapper>
      </div>
    );
  };

  const renderSubMenu = () => {
    const isHome =
      router?.route === '/' ||
      router?.route === '/webview' ||
      router?.route === `/webview/latest`;
    if (isHome) return;
    const isAll =
      router?.asPath === `/${selectedCategories}` ||
      router?.asPath === `/webview/${selectedCategories}`;

    if (!hasSubMenu) return;
    return (
      <SubCateBoxWrapper $show={scrollDirection !== 'down'}>
        <div
          style={{
            padding: '0 20px',
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            overflow: 'scroll',
            whiteSpace: 'nowrap',
            margin: '0 auto',
            maxWidth: 1240,
            scrollbarWidth: 'none',
          }}
        >
          <Link
            href={getRedirectUrl(`/${selectedCategories}`)}
            className={classNames('nav-sub-item text-sm-unchanged ', {
              'nav-ani-selected-3': isAll,
              '': !isAll,
            })}
          >
            {t('all')}
          </Link>
          {postCategories &&
            postCategories
              .find((pc: any) => {
                return pc.post_category_slug === selectedCategories;
              })
              ?.post_category_item?.map((navItem: any) => {
                const selected =
                  selectedSubCategories === navItem.post_category_item_slug;
                return (
                  <Link
                    key={navItem.post_category_slug}
                    href={getRedirectUrl(
                      `/${selectedCategories}/${navItem.post_category_item_slug}`
                    )}
                    className={classNames('nav-sub-item text-sm-unchanged', {
                      'nav-ani-selected-3': selected,
                      '': !selected,
                    })}
                  >
                    {navItem?.post_category_item_name[newLang]}
                  </Link>
                );
              })}
        </div>
      </SubCateBoxWrapper>
    );
  };

  const renderSearch = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBlockEnd: '12px',
          }}
        >
          <h5
            style={{
              marginBlockStart: 0,
              marginBlockEnd: 0,
              fontSize: '1rem',
            }}
          >
            {t('search')}
          </h5>
          {webView && (
            <div
              style={{
                padding: '2px 0',
              }}
            >
              <SearchInput
                onHandleCollapse={onHandleCollapse}
                collapseState={collapse}
                value={keyword ?? ''}
                onChange={handleInputChange}
                onHandleSubmit={handleSubmit}
                showClose={true}
              />
            </div>
          )}
        </div>
        <StyledSearchBox onSubmit={handleSubmit} action="/" target="_top">
          <StyledImage
            src={SearchIcon.src}
            alt=""
            width={20}
            height={20}
            imageStyle={{
              height: '20px',
              width: '20px',
              padding: '0 6px',
            }}
          />
          <StyledInput
            type="text"
            name="keyword"
            className=""
            placeholder="Search News"
            value={keyword ?? ''}
            onChange={handleInputChange}
          />
          <StyledButton type="submit">{t('search')}</StyledButton>
        </StyledSearchBox>
      </>
    );
  };

  const renderLanguage = () => {
    const selectedLang = router?.locale;
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBlockEnd: '12px',
          }}
        >
          <MenuTitle
            style={{
              marginBlockStart: 0,
              marginBlockEnd: 0,
            }}
          >
            {t('language')}
          </MenuTitle>
        </div>
        <SubMenu>
          <MenuItem
            className={classNames('', {
              'text-secondary': selectedLang === 'en',
            })}
            onClick={() => {
              handleChange('en');
            }}
          >
            <span>English</span>
          </MenuItem>
          <MenuItem
            className={classNames('', {
              'text-secondary': selectedLang === 'ms',
            })}
            onClick={() => {
              handleChange('ms');
            }}
          >
            <span>Bahasa Malaysia</span>
          </MenuItem>
          <MenuItem
            className={classNames('', {
              'text-secondary': selectedLang === 'zh',
            })}
            onClick={() => {
              handleChange('zh');
            }}
          >
            <span>中文</span>
          </MenuItem>
        </SubMenu>
      </>
    );
  };

  const megaMenus = [
    ...(webView
      ? []
      : [
          {
            post_category_name: {
              en: 'Pages',
              bm: 'Halaman',
              cn: '页面',
            },
            noRedirect: true,
            post_category_slug: undefined,
            post_category_item: [],
            modules: MODULE_PATHS,
          },
        ]),
    ...(isNewsModule ? postCategories : []),
  ];

  const renderMegaMenu = () => {
    return (
      <div
        className="list"
        style={{
          gap: '1.25rem',
          paddingBlock: 0,
        }}
      >
        {megaMenus.map((navItem: any) => {
          const renderSubMenu = () => {
            if (navItem?.modules) {
              return (
                <SubMegaMenu
                  style={{
                    flexDirection: 'row',
                    ...(!isNewsModule && { marginBottom: 0 }),
                  }}
                >
                  {navItem?.modules?.map((item: any, index: number) => {
                    const selected =
                      (item?.path === '/cars' && isCarModule) ||
                      (item?.path === '/' && isNewsModule) ||
                      (item?.path === '/cars/ev-overview' && isEVCompare);

                    return (
                      <MegaMenuItem
                        style={{
                          minWidth: 100,
                          flex: 1,
                          paddingLeft: 0,
                        }}
                        key={index}
                      >
                        <Link
                          href={item?.path}
                          style={{
                            width: '100%',
                            display: 'block',
                            fontWeight: 500,
                            ...(selected && { color: '#31ACBF' }),
                          }}
                          onClick={(event) => {
                            if (selected) {
                              event.preventDefault();
                            }
                          }}
                        >
                          {item?.title}
                        </Link>
                      </MegaMenuItem>
                    );
                  })}
                </SubMegaMenu>
              );
            }
            if (!navItem?.post_category_item || !isNewsModule) return null;
            return (
              <SubMegaMenu>
                {navItem?.post_category_item?.map((pci: any) => {
                  const selected =
                    pathname ===
                    getRedirectUrl(
                      `/${navItem.post_category_slug}/${pci.post_category_item_slug}`
                    );
                  return (
                    <MegaMenuItem key={pci.post_category_slug}>
                      {!selected ? (
                        <Link
                          href={getRedirectUrl(
                            `/${navItem.post_category_slug}/${pci.post_category_item_slug}`
                          )}
                          style={{ width: '100%', display: 'block' }}
                        >
                          {pci?.post_category_item_name[newLang]}
                        </Link>
                      ) : (
                        <span
                          onClick={() => {
                            setCollapse(false);
                          }}
                        >
                          {pci?.post_category_item_name[newLang]}
                        </span>
                      )}
                    </MegaMenuItem>
                  );
                })}
              </SubMegaMenu>
            );
          };
          return (
            <div
              key={navItem.post_category_slug}
              className="list-item list-border"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBlockEnd: '12px',
                  minWidth: '100%',
                }}
              >
                <Link
                  href={getRedirectUrl(`/${navItem.post_category_slug}`)}
                  style={{ width: '100%' }}
                  onClick={(event) => {
                    if (navItem?.noRedirect) {
                      event.preventDefault();
                    }
                  }}
                >
                  <MenuTitle style={{ width: '100%' }}>
                    {navItem?.post_category_name[newLang]}
                  </MenuTitle>
                </Link>
              </div>

              {renderSubMenu()}
            </div>
          );
        })}
      </div>
    );
  };

  const renderMenuItem = () => {
    return (
      <div
        className={classNames('mobile-collapse', {
          'collapse-activated': collapse,
        })}
      >
        <CollapseHolder onClick={() => setCollapse(false)}>
          <CollapseInner
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              padding:
                type !== 'menu' ? '15px 20px 20px' : '15px 20px 30px 20px',
              height: 'auto',
            }}
          >
            {type === 'search' && renderSearch()}
            {type === 'language' && renderLanguage()}
            {type === 'menu' && renderMegaMenu()}
          </CollapseInner>
        </CollapseHolder>
      </div>
    );
  };

  const renderTopLeftIcon = () => {
    if (!isNewsModule && webView) return;
    if (isDetails) {
      return (
        <MenuBurgerWrap
          onClick={clickEvent('/', true)}
          style={
            isAmp
              ? { height: '16px', width: '16px' }
              : {
                  height: '16px',
                }
          }
        >
          {/* <StyledImage src={BackIcon.src} alt="" width={16} height={16} /> */}
          {/* temp solution, config changed need to find another way to render svg */}
          <BackIcon style={{ height: 16, width: 16 }} />
        </MenuBurgerWrap>
      );
    }
    return (
      <MenuBurgerWrap>
        <AnimatedBurger
          toggle={collapse && type === 'menu'}
          onClick={() => onHandleCollapse(!collapse, 'menu')}
        />
      </MenuBurgerWrap>
    );
  };

  const renderTopLeftMenu = () => {
    return (
      <StyledTopLeftMenuWrapper>
        <CategoriesBoxWrapper $show={scrollDirection !== 'down'}>
          <HeaderBarSection
            style={{
              display: 'flex',
              flexDirection: 'row',
              whiteSpace: 'nowrap',
              margin: '0 auto',
              padding: '5px 0px',
            }}
          >
            {MODULE_PATHS.map((path) => {
              const pathUrl = getRedirectUrl(path.path);
              const paths = path.children;
              const selected = includes(paths, selectedMainNavItem);
              return (
                <Link
                  key={`topLeftMenu_${path.title}`}
                  href={pathUrl}
                  className={classNames('nav-item-header text-sm-unchanged ', {
                    'nav-ani-selected': selected,
                    'nav-ani': !selected,
                  })}
                  onClick={() => {
                    setSelectedMainNavItem(pathUrl);
                  }}
                >
                  {path.title}
                </Link>
              );
            })}
          </HeaderBarSection>
        </CategoriesBoxWrapper>
      </StyledTopLeftMenuWrapper>
    );
  };

  const renderLogo = () => {
    const homePath = isNewsModule ? '/' : '/cars';
    return (
      <LogoWrapper>
        {/*{isDetails ? (*/}
        {/*  <StyledImage*/}
        {/*    src={Logo.src}*/}
        {/*    alt=""*/}
        {/*    width={76}*/}
        {/*    height={24}*/}
        {/*    imageStyle={{*/}
        {/*      objectFit: 'contain',*/}
        {/*      height: '24px',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : (*/}
        <Link
          href={getRedirectUrl(homePath)}
          onClick={isHomePage ? undefined : clickEvent(homePath, true)}
          style={{
            display: 'block',
            maxHeight: '32px',
          }}
        >
          <MbLogoInner
            style={
              isAmp
                ? {
                    height: 'auto',
                    minWidth: '76px',
                  }
                : {}
            }
          >
            <StyledImage
              src={Logo.src}
              alt=""
              width={76}
              height={24}
              imageStyle={{
                objectFit: 'contain',
                height: '24px',
              }}
            />
          </MbLogoInner>
          <DeskLogoInner
            style={
              isAmp
                ? {
                    height: 'auto',
                    minWidth: '76px',
                  }
                : {}
            }
          >
            <StyledImage
              src={Logo.src}
              alt=""
              width={76}
              height={24}
              imageStyle={{
                objectFit: 'contain',
                height: '32px',
              }}
            />
          </DeskLogoInner>
        </Link>
        {/*)}*/}
      </LogoWrapper>
    );
  };

  const renderSearchBar = () => {
    if (!isNewsModule) return <></>;
    return (
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        {!isDetails && (
          <div
            style={{
              padding: '12px 0',
            }}
          >
            <SearchInput
              onHandleCollapse={onHandleCollapse}
              collapseState={collapse}
              value={keyword ?? ''}
              onChange={handleInputChange}
              onHandleSubmit={handleSubmit}
            />
          </div>
        )}

        {(!isDetails || !webView) && (
          <LanguageIconBox>
            <LanguageIconWrapper
              onClick={() => {
                onHandleCollapse(!collapse, 'language');
              }}
            >
              <>
                <LanguageTag>
                  {languageOptions.find((opt) => {
                    return opt.value === selectedOption;
                  })?.label ?? selectedOption}
                </LanguageTag>
                {SocialIcons['language']}
              </>
            </LanguageIconWrapper>
          </LanguageIconBox>
        )}
        <LanguageWrapper>
          <SelectDropdown
            options={languageOptions}
            value={selectedOption}
            onChange={handleChange}
            displayIcon={true}
            icon={
              <LanguageWrapper
                style={{
                  width: 18,
                  height: 18,
                }}
              >
                <LanguageTag>
                  {languageOptions.find((opt) => {
                    return opt.value === selectedOption;
                  })?.label ?? selectedOption}
                </LanguageTag>
                {SocialIcons['language']}
              </LanguageWrapper>
            }
          />
        </LanguageWrapper>
      </div>
    );
  };

  // ======================= VIEWS
  return (
    <StyledMainWrapper $containerNoCss={containerNoCss}>
      <nav
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!hideHeader && (
          <div
            style={{
              padding: '0 20px',
              borderBottom: '1px solid #ECECEC',
              boxShadow: isDetails
                ? 'rgba(0, 0, 0, 0.08) 0px 4px 16px 0px'
                : 'unset',
            }}
          >
            <div
              style={{
                margin: '0 auto',
                maxWidth: 1240,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                position: 'relative',
                height: '50px',
              }}
            >
              {renderTopLeftMenu()}
              {renderTopLeftIcon()}
              {renderLogo()}
              {renderSearchBar()}
            </div>
          </div>
        )}

        {/* collapsible menu */}
        <div style={{ position: 'relative' }}>
          {collapse && (
            <div
              style={{
                position: 'absolute',
                // top: !isDetails ? 0 : 48,
                top: 0,
                bottom: 0,
                minHeight: '100vh',
                width: '100%',
                overflow: 'scroll',
              }}
            >
              {renderMenuItem()}
            </div>
          )}
        </div>

        {/* NAV ITEMS */}
        {renderNavItems()}
      </nav>

      {!isDetails && (
        <div
          style={{
            boxShadow: '0px 4px 16px 0px #00000014',
            opacity: collapse ? 0 : 1,
          }}
        >
          {renderSubMenu()}
        </div>
      )}
    </StyledMainWrapper>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default Navbar;
